$(function() {
  $('.admin-teams, .admin-players').on('change', '#player_status', function(){
    if($(this).val() == 'inactive'){
      $('.player-in-activity').addClass('d-none');
    }
    else{
      $('.player-in-activity').removeClass('d-none');
      if($(this).val() == 'gb_playing'){
        $('.not-gb-player').addClass('d-none');
        $('.gb-player').removeClass('d-none');
      }else{
        $('.not-gb-player').removeClass('d-none');
        $('.gb-player').addClass('d-none');
      }
    }
  })
});
