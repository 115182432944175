import iziToast from "izitoast";
// import Datetimepicker from "./datetimepicker";
import DatePicker from "./pickadate"
// Datetimepicker.init();

(function($) {
  $.app = {
    attachmentContainer: undefined,
    encryptedModelData: undefined,

    initDatePicker: function(){
      DatePicker.initOnLoad();
    },
    initOnLoad: function() {
      $.app.initDatePicker();
      if($("form .newsletter-trix").length > 0){
        $.trix.initNewsletter();
        $("form .newsletter-trix").each(function(){
          var id = $(this).attr('id');
          $(this).hide();
          $(this).after('<trix-editor input="' + id + '"></trix-editor>');
        })
      }
      $(".select-with-autocomplete").each(function(){
        var $this = $(this);
        $this.select2({
          theme: 'classic',
          locale: 'it',
          language: {
            noResults: function(){
              if($this.data('url-no-result')){
                return $("<a href='" + $this.data('url-no-result') + "?element_to_append=" + $this.attr('id') + "' data-remote='true'>Aggiungi</a>")
              }
              else{
                return "(Nessun risultato)"
              }
            }
          }
        });
      })
      $(".sortable").sortable(
        {
          handle: ".sort-handle",
          tolerance: 'pointer',
          forcePlaceholderSize: true,
          opacity: 0.7,
        }
      );

      $('[data-toggle="popover"]').popover({
        placement: 'top',
        trigger: 'hover'
      });

      $('[data-toggle="tooltip"]').tooltip()

    },
    flashMessage: function(name, msg, position){
      var obj = {
        message: msg,
        timeout: 8000,
        position: position
      }
      if(name == 'error'){
        obj.title = 'Errore'
        obj.color = 'red'
      }else if(name == 'notice'){
        obj.title = 'Successo'
        obj.color = 'green'
      }else if(name == 'alert'){
        obj.title = 'Attenzione'
        obj.color = 'yellow'
      }
      iziToast.show(obj);
    },
    guid: function () {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }

      return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
    },
    parameterize: function(string){
      return string.replace(/[ÀÁàá]/g, 'a').replace(/[ÈÉèé]/g,'e').replace(/[ÌÍìí]/g,'i').replace(/[ÒÓòó]/g,'o').replace(/[ÙÚùú]/g,'u').replace(/[^\w]/g, '-').replace(/\-\-+/g, '-').toLowerCase()
    },
    newAttachment: function (modal, model_data) {
      $.app.encryptedModelData = model_data;
      if(modal==='newFile'){
        $('#single-attachment').modal('show');
      }else if(modal==='fromArchive'){
        $.get('/admin/attachments');
//        if($('#attachments-archive .modal-body .attachments a').length == 0){
//        }
        $('#attachments-archive').modal('show');
      }
    },
    createAttachment: function (modal) {
      var formData = new FormData();
      if(modal==='newFile'){
        var $file = $("#single-attachment-input");
        if ($file[0].files[0] === undefined) {
          $.app.flashMessage('error', 'Nessun file selezionato', 'center');
          return
        }
        formData.append('attachment_file', $file[0].files[0]);
      }else{
        formData.append('attachment_blob_id', modal);
      }
      formData.append('model_data', $.app.encryptedModelData);
      $.ajax({
        url: '/admin/create_attachment',
        type: 'POST',
        data: formData,
        contentType: false,
        processData: false,
        beforeSend: function () {
          $(".ajax-load").removeClass('d-none');
        },
        done: function () {
          $(".ajax-load").addClass('d-none');
        }
      });
    },
    moveBlockDown: function($block){
      var $next = $block.next();
      if($next[0] != undefined){
        $block.insertAfter($next);
        $.app.setMoveArrows($block.closest('.sortable'));
      }
    },
    moveBlockUp: function($block){
      var $prev = $block.prev();
      if($prev[0] != undefined){
        $block.insertBefore($prev);
        $.app.setMoveArrows($block.closest('.sortable'));
      }
    },
    moveBlockBottom: function($block){
      var $last = $block.closest('.sortable').find('.card').last();
      $block.insertAfter($last);
      $.app.setMoveArrows($block.closest('.sortable'));
    },
    moveBlockTop: function($block){
      var $first = $block.closest('.sortable').find('.card').first();
      $block.insertBefore($first);
      $.app.setMoveArrows($block.closest('.sortable'));
    },
    setMoveArrows: function($sortable){
      if($sortable.find('.card').length == 1){
        $sortable.find('.move').addClass('d-none');
      }
      else{
        $sortable.find('.move').removeClass('d-none');
        $sortable.find('.card').first().find('.move-up, .move-top').addClass('d-none');
        $sortable.find('.card').last().find('.move-down, .move-bottom').addClass('d-none');
      }
    },
    copyToClipboard: function (text,id) {
      var $temp = $("<input>");
      $("body").append($temp);
      $temp.val(text).select();
      document.execCommand("copy");
      $temp.remove();
      iziToast.show(
        {
          message: "Link copiato",
          timeout: 1000,
          color: 'green',
          close: false,
          maxWidth: '150',
          position: 'center'
        }
      );
    },
    readableBytes: function(bytes) {
      var i = Math.floor(Math.log(bytes) / Math.log(1024)),
          sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i];
    },
    stripeCheckout: function(public_key, session_id){
      let stripe = Stripe(public_key);
      stripe.redirectToCheckout({
        sessionId: session_id
      })
    }
  }
})(jQuery);
