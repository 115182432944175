$(function() {
  $('.inscriptions, .admin-inscriptions').on('change', "#inscription_applicant_resides_with_son", function(){
    var applicant_address_area = $(this).closest('form').find('.applicant-address-data');
    if($(this).prop('checked')){
      applicant_address_area.addClass('d-none')
    }else{
      applicant_address_area.removeClass('d-none')
    }
  }).on('change', "#inscription_receipt_of_payment_same_as_applicant", function(){
    var receipt_of_payment_data = $(this).closest('form').find('.receipt-of-payment-data');
    if($(this).prop('checked')){
      receipt_of_payment_data.addClass('d-none')
    }else{
      receipt_of_payment_data.removeClass('d-none')
    }
  });
});