import "./picker"
import "./picker.date"
import "./it_IT"

function initOnLoad(){
  $('[data-provide="datepicker"]').each(function(){
    $(this).pickadate({
      format: "dd/mm/yyyy",
      formatSubmit: "yyyy-mm-dd",
      hiddenName: true,
      editable: false,
      selectYears: 100,
      selectMonths: true,
      max: 365
    })
  });
}

export default { initOnLoad }