const Trix = require('trix');

const trixToolbars = {
  init: function() {
    Trix.config.textAttributes = {
      ...Trix.config.textAttributes,
      small: {
        tagName: "small",
        inheritable: true
      }
    };
    Trix.config.blockAttributes = {
      ...Trix.config.blockAttributes,
      heading2: {
        tagName: "h2",
        terminal: true,
        breakOnReturn: true,
        group: false,
      },
      heading3: {
        tagName: "h3",
        terminal: true,
        breakOnReturn: true,
        group: false,
      }
    };
    Trix.config.toolbar = {
      getDefaultHTML: function() {
        return trixToolbars.default();
      }
    };
  },
  default: function(){
    // Bold, Italic, Link, H2, H3, Elenco puntato, Elenco numerato
    return `
      <div class="trix-button-row">
        <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
          <button type="button" class="trix-button" data-trix-attribute="bold" data-trix-key="b" title="Grassetto" tabindex="-1">
            <i class="fa fa-bold"></i>
          </button>
          <button type="button" class="trix-button" data-trix-attribute="italic" data-trix-key="i" title="Corsivo" tabindex="-1">
            <i class="fa fa-italic"></i>
          </button>
          <button type="button" class="trix-button" data-trix-attribute="small" data-trix-key="s" title="Riduci carattere" tabindex="-1">
            <i class="far fa-hand-lizard"></i>
          </button>
          <button type="button" class="trix-button" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="Link" tabindex="-1">
            <i class="fa fa-link"></i>
          </button>
          <button type="button" class="trix-button" data-trix-attribute="heading2" title="Titolo H2" tabindex="-1">
            H2
          </button>
          <button type="button" class="trix-button" data-trix-attribute="heading3" title="Titolo H3" tabindex="-1">
            H3
          </button>
          <button type="button" class="trix-button" data-trix-attribute="bullet" title="Lista" tabindex="-1">
            <i class="fa fa-list-ul"></i>
          </button>
          <button type="button" class="trix-button" data-trix-attribute="number" title="Lista numerata" tabindex="-1">
            <i class="fa fa-list-ol"></i>
          </button>
          <button type="button" class="trix-button" data-trix-action="undo" data-trix-key="z" title="Undo" tabindex="-1">
            <i class="fas fa-undo"></i>
          </button>
          <button type="button" class="trix-button" data-trix-action="redo" data-trix-key="shift+z" title="Redo" tabindex="-1">
            <i class="fas fa-redo"></i>
          </button>
        </span>
      </div>
      <div class="trix-dialogs" data-trix-dialogs>
        <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
          <div class="trix-dialog__link-fields">
            <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="url" required data-trix-input>
            <div class="trix-button-group">
              <input type="button" class="trix-button trix-button--dialog" value="Imposta link" data-trix-method="setAttribute">
              <input type="button" class="trix-button trix-button--dialog" value="Elimina link" data-trix-method="removeAttribute">
            </div>
          </div>
        </div>
      </div>
    `;
  }
};

export default trixToolbars;
