$(function() {
  $('.admin-seasons').on('click', '.add-rate', function(){
    $(".admin-seasons .select-rates").removeClass('d-none')
  })

  $('.admin-seasons').on('change', '.select-rates', function(){
    var num = parseInt($(this).val())
    var name = $(".select-rates option:selected").text()
    var code = $(".select-rates option:selected").data('code')
    var $available_rate = $('<div class="option"><i class="fas fa-trash float-right remove"></i></div>');
    $available_rate.append(`<h4>${name}</h4>`)
    for (i = 0; i < num; i++) {
      $available_rate.append($.season.addAvailableRatesPerc(code, '', i) +
          $.season.addAvailableRatesDesc(code, '', i) +
          $.season.addAvailableRatesDate(code, '', i) + '<br>');
    }
    $('.rates').append($available_rate)
    $(".select-rates").addClass('d-none')
    $.app.initDatePicker();
  })

  $('.admin-seasons').on('click', '.rates .option .remove', function(){
    $(this).closest('.option').remove();
  })
});

(function($) {
  $.season = {
    addAvailableRatesPerc: (rate_code, value, index) => {
      const input_id = `available_rates_${rate_code}_${index}_perc`;
      return `<div class="form-group decimal required perc d-inline-block">` +
          `<label class="decimal required" for="${input_id}">Percentuale <abbr title="richiesto">*</abbr></label>` +
          `<input class="form-control numeric decimal required" value="${value}" type="number" name="season[available_rates][${rate_code}][][perc]" id="${input_id}">` +
          `</div>`
    },
    addAvailableRatesDesc: (rate_code, value, index) => {
      const input_id = `available_rates_${rate_code}_${index}_description`;
      return `<div class="form-group string required description d-inline-block">` +
          `<label class="string required" for="${input_id}">Descrizione <abbr title="richiesto">*</abbr></label>` +
          `<input class="form-control string required" type="text" value="${value}" name="season[available_rates][${rate_code}][][description]" id="${input_id}">` +
          `</div>`
    },
    addAvailableRatesDate: (rate_code, value, index) => {
      const input_id = `available_rates_${rate_code}_${index}_expiry_date`;
      return `<div class="form-group date_picker required expiry_date d-inline-block">` +
          `<label class="date_picker required" for="${input_id}">Scadenza <abbr title="richiesto">*</abbr></label>` +
          `<div class="input-group date-wrapper">` +
          `<input class="form-control date_picker required" value="${value}" data-provide="datepicker" type="text" name="season[available_rates][${rate_code}][][expiry_date]" id="${input_id}">` +
          `<div class="input-group-append">` +
          `<span class="input-group-text"><i class="far fa-calendar-alt"></i></span>` +
          `</div>` +
          `</div>` +
          `</div>`
    }
  }
})(jQuery);
