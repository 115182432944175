$(function() {
  $('.admin-coaches').on('change', '#coach_is_activity', function(){
    if($(this).val() == 'true'){
      $('.coach-in-activity').removeClass('d-none');
    }
    else{
      $('.coach-in-activity').addClass('d-none');
    }
  }).on('change', '#coach_is_gb_coaching', function(){
    if($(this).val() == 'false'){
      $('.not-gb-coach').removeClass('d-none');
    }
    else{
      $('.not-gb-player').addClass('d-none');
    }
  });
});
