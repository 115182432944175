import "bootstrap";
import "./cocoon"
import "select2";

import "./gemonabasket/players";
import "./gemonabasket/editables";
import "./gemonabasket/teams";
import "./gemonabasket/inscriptions";
import "./gemonabasket/coaches";
import "./gemonabasket/seasons";
import "./app";
import trixToolbars from "./trix";
import Rails from '@rails/ujs';
import {Stripe} from '@stripe/stripe-js';
import yall from "yall-js";

trixToolbars.init();
document.addEventListener("DOMContentLoaded", yall);
$(function() {
  // const container = document.querySelector('.fireworks-container')
  // if(container){
  //   const fireworks = new Fireworks(container, { delay: { min: 30, max: 100 } })
  //   fireworks.start()
  // }

  $(document).on('submit', '.navbar form.search', function(event){
    if($(this).find('input').val().trim().length == 0){
      $.app.flashMessage('error', 'Il campo ricerca non può essere vuoto', 'topRight');
      event.preventDefault();
      event.stopPropagation();
    }
  });

  $(document).on('click', '#filter_blobs input[type=checkbox]', function(){
    if($(this).prop('checked') && $(this).val() != 'all'){
      $('#all_attachments').prop('checked', false)
    }
    Rails.fire($(this).closest('form')[0], 'submit')
  });

  $(document).on('click', '.date-wrapper .input-group-append', function(){
    $(this).closest('.date-wrapper').find('input.date_picker').trigger('click')
  });

  $(document).on('change', 'input.custom-file-input[type=file]', function(){
    var $form_group = $(this).closest('.form-group');
    $form_group.find('ul.file-list').remove();
    var $ul = $('<ul class="file-list mt-1"></ul>');
    Array.from($(this)[0].files).forEach(function(file){
      $ul.append('<li>' + file.name + ' (' + $.app.readableBytes(file.size) + ')</li>')
    })
    $form_group.append($ul);
  })

  // tutti gli eventi attaccati a $(document) NON andrebbero inizializzati nella initOnload visto che la richiamiamo
  // dopo ogni chiamata ajax
  $.app.initOnLoad();

  // form innestati (gem cocoon) inseriti
  $(document).on('cocoon:after-insert', function(e, insertedItem) {
    $.app.initOnLoad();
  });

  // dopo le chiamate ajax
  $(document).on('ajax:beforeSend', function(){
    $(".ajax-load").removeClass('d-none');
  });
  $(document).on('ajax:complete ajaxSuccess', function(xhr, status){
    $(".ajax-load").addClass('d-none');
    $.app.initOnLoad();
  });
});
